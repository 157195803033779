import { CommonModule } from '@angular/common';
import {
  inject,
  NgModule,
  Optional,
  provideAppInitializer,
  SkipSelf,
} from '@angular/core';

import { KindeAuthLoginCallbackComponent } from './kinde/components/kinde-login-callback.component';
import { KindeAuthService } from './kinde/kinde-auth.service';
import { MessageCodeAuthService } from './message-code/message-code-auth.service';

@NgModule({
  providers: [
    KindeAuthService,
    MessageCodeAuthService,
    provideAppInitializer(() => {
      const initializerFn = (
        (authService: KindeAuthService) => async (): Promise<void> => {
          await authService.initialize();
        }
      )(inject(KindeAuthService));
      return initializerFn();
    }),
  ],
  imports: [CommonModule, KindeAuthLoginCallbackComponent],
})
export class RcAuthModule {
  constructor(@Optional() @SkipSelf() parentModule?: RcAuthModule) {
    if (parentModule) {
      throw new Error(
        'RcAuthModule is already loaded. Import it once at root only',
      );
    }
  }
}
