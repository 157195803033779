import { isPlatformServer } from '@angular/common';
import {
  ApplicationRef,
  inject,
  NgModule,
  PLATFORM_ID,
  provideAppInitializer,
} from '@angular/core';
import { provideClarity } from 'ngx-clarity';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { first } from 'rxjs';
import { environment } from '../../environments/environment';
import { TrackingService } from './tracking.sevice';

const IMPORTS = [];

if (environment.tracking.enabled) {
  IMPORTS.push(
    NgxGoogleAnalyticsModule.forRoot(
      environment.tracking.trackers.googleAnalytics.measurementId,
    ),
  );
}

@NgModule({
  imports: IMPORTS,
  providers: [
    TrackingService,
    provideClarity({
      enabled: environment.tracking.enabled,
      projectId: environment.tracking.trackers.clarity.projectId,
    }),
    provideAppInitializer(() => {
      const initializerFn = ((
        trackingService: TrackingService,
        applicationRef: ApplicationRef,
        platformId,
      ) => {
        return function (): void {
          // Wait until application is stable to defer loading tracking until SSR hydration is complete
          // TODO: This appears to be deferred for about 5 seconds, resulting in layout quirks before hydration is complete
          // Need to look into how to reduce this duration
          applicationRef.isStable
            .pipe(first((isStable) => isStable))
            .subscribe(() => {
              // Only enable tracking when running in browser and tracking is enabled
              if (
                environment.tracking.enabled &&
                !isPlatformServer(platformId)
              ) {
                trackingService.init();
              }
            });
        };
      })(inject(TrackingService), inject(ApplicationRef), inject(PLATFORM_ID));
      return initializerFn();
    }),
  ],
})
export class RcTrackingModule {}
