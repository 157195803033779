import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map, switchMap, timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { KindeAuthService } from '../../auth/kinde/kinde-auth.service';
import { MessageCodeAuthService } from '../../auth/message-code/message-code-auth.service';

/**
 * Add appropriate authentication mechanisms to all Rent Chores API requests
 *
 * Inspects current app route to check whether to use Manager or Tenant authentication
 *
 * Defaults to manager auth
 */
@Injectable()
export class RcApiInterceptor implements HttpInterceptor {
  private readonly kindeAuthService = inject(KindeAuthService);
  private readonly messageCodeAuthService = inject(MessageCodeAuthService);
  private readonly router = inject(Router);

  private readonly publicRcApiRoutes: Array<RegExp> = [
    //new RegExp('/auth/.+$'),
  ];
  private readonly appManagerRoutes: Array<RegExp> = [
    new RegExp('^/manager.*$'),
  ];
  private readonly appTenantRoutes: Array<RegExp> = [new RegExp('^/tenant.*$')];

  private readonly deferForTokenTimeout = 2000;

  /**
   * Hold any requests to protected routes until an appropriate available token
   * is available, failing if the token provided before the timeout window
   */
  public intercept<T>(
    request: HttpRequest<T>,
    next: HttpHandler,
  ): Observable<HttpEvent<T>> {
    // Passthrough any requests this interceptor should ignore
    if (!this.isRcApiProtectedRoute(request.url)) {
      return next.handle(request);
    }

    const appRoute = this.getTargetAppRoute();

    if (this.isTenantRoute(appRoute)) {
      return this.messageCodeAuthService.messageCodeToken$.pipe(
        first(),
        timeout(this.deferForTokenTimeout),
        map((token) => this.addMessageCodeToken(request, token)),
        switchMap((request) => next.handle(request)),
      );
    } else if (this.isManagerRoute(appRoute)) {
      return this.kindeAuthService.accessToken$.pipe(
        first(),
        timeout(this.deferForTokenTimeout),
        map((token) => this.addBearerAccessToken(request, token)),
        switchMap((request) => next.handle(request)),
      );
    }

    // Should only make it this far when on landing routes currently
    // Used now that CMS API is proxied through primary API
    return next.handle(request);
  }

  /**
   * Attach headers for authenticated user JWT access token
   */
  private addBearerAccessToken<T>(
    request: HttpRequest<T>,
    token: string,
  ): HttpRequest<T> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  /**
   * Attach headers for directly provided message code token from URL query params
   */
  private addMessageCodeToken<T>(
    request: HttpRequest<T>,
    token: string,
  ): HttpRequest<T> {
    return request.clone({
      setHeaders: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Rc-Message-Code': token,
      },
    });
  }

  /**
   * Returns true when making requests to any non-public routes against the RC API
   */
  private isRcApiProtectedRoute(route: string): boolean {
    if (route.startsWith(environment.apiUrl)) {
      return !this.publicRcApiRoutes.some((publicRoute) =>
        publicRoute.test(route),
      );
    }

    return false;
  }

  /**
   * Returns the best route URL given current context, either the target URL when navigating
   * or the current page URL otherwise
   */
  private getTargetAppRoute(): string {
    const nav = this.router.getCurrentNavigation()!;

    if (nav) {
      return this.router.serializeUrl(nav.finalUrl || nav.extractedUrl);
    } else {
      return this.router.routerState.snapshot.url;
    }
  }

  private isManagerRoute(route: string): boolean {
    return this.appManagerRoutes.some((routePattern) =>
      routePattern.test(route),
    );
  }

  private isTenantRoute(route: string): boolean {
    return this.appTenantRoutes.some((routePattern) =>
      routePattern.test(route),
    );
  }
}
