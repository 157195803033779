import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ApolloModule } from 'apollo-angular';
import { ArticlesService } from './services/articles.service';
import { DocumentsService } from './services/documents.service';
import { OrganizationService } from './services/organization.service';
import { PropertyUnitService } from './services/property-unit.service';
import { PropertyService } from './services/property.service';
import { RcApiInterceptor } from './services/rc-api.interceptor';
import { RcApiService } from './services/rc-api.service';
import { TaskScheduleService } from './services/task-schedule.service';
import { TaskTemplatesService } from './services/task-templates.service';
import { TaskService } from './services/task.service';
import { TenantService } from './services/tenant.service';
import { UserService } from './services/user.service';

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RcApiInterceptor,
      multi: true,
    },
    RcApiService,
    OrganizationService,
    PropertyService,
    PropertyUnitService,
    TaskService,
    TaskScheduleService,
    UserService,
    TenantService,
    DocumentsService,
    ArticlesService,
    TaskTemplatesService,
  ],
  declarations: [],
  imports: [CommonModule, ApolloModule],
})
export class RcApiModule {}
