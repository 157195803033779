import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TagModule } from 'primeng/tag';
import { environment } from '../../environments/environment';

@Component({
  imports: [CommonModule, TagModule],
  selector: 'rc-dev-mode-banner',
  styles: `
    #rcDevModeBanner {
      position: fixed;
      top: 0rem;
      left: 0rem;
      z-index: 9999;
      cursor: default;
      transform: translateY(1rem) rotate(-90deg) translateX(-0.5rem)
        translateY(-1rem);
    }
  `,
  template: `
    @if (devMode) {
      <div id="rcDevModeBanner">
        <p-tag
          value="RC Dev"
          icon="pi pi-info-circle"
        ></p-tag>
      </div>
    }
  `,
})
export class RcDevModeBannerComponent {
  public readonly devMode = !environment.production;
}
